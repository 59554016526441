import React from "react";

export default function Typing() {
  return (
    <section id="nt-typing">
      <div className="nt-typing__textbox">
        <span>Wake up, Neo... We've got job to do.</span>
      </div>
    </section>
  );
}
