import React from "react";
import "./styles/App.scss";
import SectionBrand from "./components/SectionBrand";
import Typing from "./components/Typing";

function App() {
  return (
    <main className="App">
      <SectionBrand />
      <Typing />
    </main>
  );
}

export default App;
