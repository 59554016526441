import React from "react";
import logo from "../assets/logo/logo.png";

export default function SectionBrand() {
  return (
    <section id="nt-brand-logo">
      <figure>
        <img src={logo} alt="NinjasTeam - Logo" />
      </figure>
    </section>
  );
}
